import {
  useDisclosure,
  Center,
  Alert,
  AlertIcon,
  Button,
  Flex,
  Heading,
  Spacer,
  Stack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Link,
  useToast,
} from '@chakra-ui/react';
import { Api } from 'api';
import {
  ExpertiseBodyworkExpertiseChassisDrawer,
  ExpertiseBodyworkExpertiseDrawer,
  ExpertiseBodyworkIcDisControlSelector,
  ExpertiseBodyworkMicronRecorder,
} from 'apps/expertise/components';
import { ExpertiseTramer, LoadingText, NoteAutoCompleteSelect } from 'components';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import {
  ApplicationToggleType,
  CustomerQueryCacheKey,
  ExpertiseDamageNoteType,
  ExpertisePackageAbilityDefinitionType,
  IntegrationQueryCacheKey,
  ProductType,
  ProductTypeQueryCacheKey,
  TramerQueryType,
} from 'types';
import React, { useEffect, useState } from 'react';
import { Socket } from 'socket.io-client';
import { createCustomerSocket, createToggleSocket, useCheckAbilityCompleted } from 'core';

export const ExpertiseBodyworkInformationPage: React.FC = () => {
  const customer = useSelector((state: RootState) => state.customer.focusedCustomer);
  const {
    isOpen: isExpertiseTramerWarningOpen,
    onOpen: onExpertiseTramerWarningOpen,
    onClose: onExpertiseTramerWarningClose,
  } = useDisclosure();
  const { isOpen: isExpertiseOpen, onOpen: onExpertiseOpen, onClose: onExpertiseClose } = useDisclosure();
  const { isOpen: isMicronRecoredOpen, onOpen: onMicronRecorderOpen, onClose: onMicronRecoredClose } = useDisclosure();
  const {
    isOpen: isExpertiseTramerModalOpen,
    onOpen: onExpertiseTramerModalOpen,
    onClose: onExpertiseTramerModalClose,
  } = useDisclosure();
  const {
    isOpen: isChassisControlOpen,
    onOpen: onChassisControlOpen,
    onClose: onChassisControlClose,
  } = useDisclosure();
  const {
    isOpen: isIcVeDisKontrolOpen,
    onOpen: onIcVeDisKontrolOpen,
    onClose: onIcVeDisKontrolClose,
  } = useDisclosure();

  const canCustomerContinue = useSelector((state: RootState) => state.customer.canCustomerContinue);
  const customerAbilityDefinitionTable = useSelector(
    (state: RootState) => state.customer.customerAbilityDefinitionTable,
  );
  const toast = useToast();

  const [isHasarKaydiEnabled, setIsHasarKaydiEnabled] = React.useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [customerSocket, setCustomerSocket] = useState<Socket | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [toggleSocket, setToggleSocket] = useState<Socket | null>(null);

  const { data: customerVehicle, isLoading } = useQuery(
    CustomerQueryCacheKey.GetCustomerVehicle,
    async () => {
      const response = await Api.Customer.getCustomerVehicle(customer!.id);
      if (!response.data) {
        return null;
      }
      const getCustomerVehicleResponse = response.data;
      return getCustomerVehicleResponse.customerVehicle;
    },
    {
      cacheTime: 0,
      retry: 0,
      enabled: customer != null,
      refetchOnWindowFocus: false,
    },
  );
  const { data: didQueriedDamageBefore } = useQuery(
    [IntegrationQueryCacheKey.GetTramerOldQueries, customer!.id],
    async () => {
      const { data } = await Api.Integration.getOldTramerQueries(customer!.id, TramerQueryType.Damage);
      return data && data.length > 0;
    },
    {
      cacheTime: 0,
      retry: 0,
      enabled: customer != null && customerVehicle != null && customerVehicle.brandId != null && canCustomerContinue,
      refetchOnWindowFocus: false,
    },
  );

  const { data: TramerResultProductPrice } = useQuery(
    ProductTypeQueryCacheKey.GetTramerQuery,
    async () => {
      const response = await Api.Util.getProductPriceByType(ProductType.TramerQuery);
      return response.data;
    },
    {
      enabled: isExpertiseTramerWarningOpen,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      onError: (error: any) => {
        const {
          response: {
            data: { message },
          },
        } = error;
        toast({
          title: message,
          status: 'error',
          isClosable: true,
        });
      },
    },
  );

  useQuery(
    ['getToggleEnabledHasarKaydi', ApplicationToggleType.DamageQuery],
    async () => {
      const response = await Api.Util.getToggleEnabled(ApplicationToggleType.DamageQuery);
      return response?.data;
    },
    {
      onSuccess: (data) => {
        setIsHasarKaydiEnabled(data?.isEnabled);
      },
      onError: () => {
        toast({
          title: 'Şu an oto sorgudaki arıza sebebi ile hasar kaydı sorgulama işleminiz yapılmamıştır',
          status: 'error',
        });
      },
      refetchOnWindowFocus: false,
    },
  );

  const { data: isExpertiseTramerResultCompleted, refetch: refetchIsExpertiseTramerResultCompleted } =
    useCheckAbilityCompleted(customer?.id, ExpertisePackageAbilityDefinitionType.HasarKaydiSorgulama);

  const { data: isMicronRecorderResultsCompleted, refetch: refetchIsMicronRecorderResultsCompleted } =
    useCheckAbilityCompleted(customer?.id, ExpertisePackageAbilityDefinitionType.MikronKaydiFormu);

  const { data: isExpertiseResultsCompleted, refetch: refetchIsExpertiseResultsCompleted } = useCheckAbilityCompleted(
    customer?.id,
    ExpertisePackageAbilityDefinitionType.BoyaDegisenFormu,
  );

  const { data: isChassisControlResultsCompleted, refetch: refetchIsChassisControlResultsCompleted } =
    useCheckAbilityCompleted(customer?.id, ExpertisePackageAbilityDefinitionType.SasiKontrol);

  const { data: isIcVeDisKontrolFormuCompleted, refetch: refetchIsIcVeDisKontrolFormuCompleted } =
    useCheckAbilityCompleted(customer?.id, ExpertisePackageAbilityDefinitionType.IcVeDisKontrolFormu);

  const onExpertiseTramerButtonClicked = () => {
    if (didQueriedDamageBefore) {
      onExpertiseTramerModalOpen();
    } else {
      onExpertiseTramerWarningOpen();
    }
  };

  useEffect(() => {
    if (customer) {
      const newCustomerSocket = createCustomerSocket(customer.id.toString());
      setCustomerSocket(newCustomerSocket);

      newCustomerSocket.on(
        'customerAbilityCompleted',
        ({ customerId, abilityDefinitionType }: { customerId: number; abilityDefinitionType: number }) => {
          if (Number(customerId) === customer.id) {
            if (abilityDefinitionType === ExpertisePackageAbilityDefinitionType.HasarKaydiSorgulama) {
              refetchIsExpertiseTramerResultCompleted();
            }
            if (abilityDefinitionType === ExpertisePackageAbilityDefinitionType.MikronKaydiFormu) {
              refetchIsMicronRecorderResultsCompleted();
            }
            if (abilityDefinitionType === ExpertisePackageAbilityDefinitionType.BoyaDegisenFormu) {
              refetchIsExpertiseResultsCompleted();
            }
            if (abilityDefinitionType === ExpertisePackageAbilityDefinitionType.SasiKontrol) {
              refetchIsChassisControlResultsCompleted();
            }
            if (abilityDefinitionType === ExpertisePackageAbilityDefinitionType.IcVeDisKontrolFormu) {
              refetchIsIcVeDisKontrolFormuCompleted();
            }
          }
        },
      );

      return () => {
        newCustomerSocket.disconnect();
      };
    }
    return undefined;
  }, [customer]);

  useEffect(() => {
    const newToggleSocket = createToggleSocket();
    setToggleSocket(newToggleSocket);

    newToggleSocket.on('toggleChanged', (data: { toggleType: number; isEnabled: boolean }) => {
      if (data?.toggleType === ApplicationToggleType.DamageQuery) {
        setIsHasarKaydiEnabled(data?.isEnabled);
      }
    });

    return () => {
      newToggleSocket.disconnect();
    };
  }, []);

  if (isLoading || !customer) {
    return (
      <Center>
        <LoadingText />
      </Center>
    );
  }

  let micronAppUrl = '';
  if (
    customerVehicle != null &&
    customerVehicle.vehicleTypeId > 0 &&
    customerAbilityDefinitionTable[ExpertisePackageAbilityDefinitionType.MikronKaydiFormu] != null
  ) {
    const micronAppUrlBuilder = new URL('deeplink://https://ekspertiz.occocar.com/');
    micronAppUrlBuilder.searchParams.append('token', JSON.parse(localStorage.authUserInfo).token);
    micronAppUrlBuilder.searchParams.append('customerId', customer!.id.toString());
    if (customerVehicle != null && customerVehicle.vehicleTypeId > 0)
      micronAppUrlBuilder.searchParams.append('vehicleTypeId', customerVehicle!.vehicleTypeId.toString());
    micronAppUrlBuilder.searchParams.append('expertiseBodyDamagePartTypeId', '2');
    micronAppUrl = micronAppUrlBuilder.href;
  } else {
    micronAppUrl = '#';
  }

  return (
    <>
      {customer && customerVehicle && customerVehicle.vehicleType && (
        <>
          <ExpertiseBodyworkExpertiseDrawer
            isOpen={isExpertiseOpen}
            onClose={() => {
              onExpertiseClose();
              refetchIsExpertiseResultsCompleted();
            }}
            carBodyType={customerVehicle.vehicleType.name}
            isDisabled={!canCustomerContinue}
          />
          <ExpertiseBodyworkMicronRecorder
            isOpen={isMicronRecoredOpen}
            onClose={() => {
              onMicronRecoredClose();
              refetchIsMicronRecorderResultsCompleted();
            }}
            carBodyType={customerVehicle.vehicleType.name}
            customerId={customer.id}
            vehicleTypeId={customerVehicle.vehicleTypeId}
            isDisabled={!canCustomerContinue}
          />
          <ExpertiseBodyworkExpertiseChassisDrawer
            isOpen={isChassisControlOpen}
            onClose={() => {
              onChassisControlClose();
              refetchIsChassisControlResultsCompleted();
            }}
            carBodyType={customerVehicle.vehicleType.name}
            isDisabled={!canCustomerContinue}
          />
          <ExpertiseBodyworkIcDisControlSelector
            isOpen={isIcVeDisKontrolOpen}
            onClose={() => {
              onIcVeDisKontrolClose();
              refetchIsIcVeDisKontrolFormuCompleted();
            }}
            isDisabled={!canCustomerContinue}
          />
        </>
      )}
      {customerVehicle && customerVehicle.chassisNumber && (
        <>
          <ExpertiseTramer
            isOpen={isExpertiseTramerModalOpen}
            onClose={() => {
              onExpertiseTramerModalClose();
              refetchIsExpertiseTramerResultCompleted();
            }}
            customerId={customer.id}
          />
          {TramerResultProductPrice && (
            <Modal isOpen={isExpertiseTramerWarningOpen} onClose={onExpertiseTramerWarningClose} isCentered>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Hasar Sorgu Onay?</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Text>
                    Hasar Kaydı Sorgusu için ₺{TramerResultProductPrice} sorgu bedeli kullanılacaktır. Devam etmek
                    istiyor musunuz ?
                  </Text>
                </ModalBody>
                <ModalFooter>
                  <Button
                    colorScheme="blue"
                    mr={3}
                    onClick={() => {
                      onExpertiseTramerModalOpen();
                      onExpertiseTramerWarningClose();
                    }}
                  >
                    Evet
                  </Button>
                  <Button onClick={onExpertiseTramerWarningClose}>Hayır</Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          )}
        </>
      )}

      <Flex align="stretch" direction="column" basis="100%" gap="2">
        <Heading as="h4" size="md">
          Kaporta Bilgileri
        </Heading>
        <Spacer />
        {!customerVehicle?.vehicleTypeId && (
          <Alert status="warning">
            <AlertIcon />
            Araç Türü Kayıt Edilmeden Mikron Kayıt Alma ve Boya Değişen İşaretle işlemleri yapılamaz
          </Alert>
        )}
        {!customerVehicle?.chassisNumber && (
          <Alert status="warning">
            <AlertIcon />
            Şasi numarası girilmeden Hasar Kaydı Sorgulama yapılamaz
          </Alert>
        )}
        <Stack direction={['column', 'column', null, 'row']} spacing="24px">
          <Button
            border={isExpertiseTramerResultCompleted ? '2px solid green' : 'none'}
            disabled={
              !customerVehicle?.chassisNumber ||
              !customerAbilityDefinitionTable[ExpertisePackageAbilityDefinitionType.HasarKaydiSorgulama] ||
              !isHasarKaydiEnabled
            }
            onClick={onExpertiseTramerButtonClicked}
          >
            Hasar Kaydı Sorgula
          </Button>
          <Button
            border={isMicronRecorderResultsCompleted ? '2px solid green' : 'none'}
            onClick={onMicronRecorderOpen}
            disabled={
              !customerVehicle?.vehicleTypeId ||
              !customerAbilityDefinitionTable[ExpertisePackageAbilityDefinitionType.MikronKaydiFormu]
            }
          >
            Mikron Kaydı Al
          </Button>

          <Button
            border={isMicronRecorderResultsCompleted ? '2px solid green' : 'none'}
            disabled={
              !customerVehicle?.vehicleTypeId ||
              !customerAbilityDefinitionTable[ExpertisePackageAbilityDefinitionType.MikronKaydiFormu]
            }
          >
            <Link href={micronAppUrl}> Cihaz İle Mikron Kaydı Al</Link>
          </Button>

          <Button
            border={isExpertiseResultsCompleted ? '2px solid green' : 'none'}
            onClick={onExpertiseOpen}
            disabled={
              !customerVehicle?.vehicleTypeId ||
              !customerAbilityDefinitionTable[ExpertisePackageAbilityDefinitionType.BoyaDegisenFormu]
            }
          >
            Boya Değişen İşaretle
          </Button>

          <Button
            border={isChassisControlResultsCompleted ? '2px solid green' : 'none'}
            onClick={onChassisControlOpen}
            disabled={
              !customerVehicle?.vehicleTypeId ||
              !customerAbilityDefinitionTable[ExpertisePackageAbilityDefinitionType.SasiKontrol]
            }
          >
            Direk ve Şasi Kontrolleri
          </Button>
          <Button
            border={isIcVeDisKontrolFormuCompleted ? '2px solid green' : 'none'}
            onClick={onIcVeDisKontrolOpen}
            disabled={
              !customerVehicle?.vehicleTypeId ||
              !customerAbilityDefinitionTable[ExpertisePackageAbilityDefinitionType.IcVeDisKontrolFormu]
            }
          >
            İç ve Dış Kontrolleri
          </Button>
        </Stack>
        <Spacer />

        <NoteAutoCompleteSelect
          customerId={customer.id}
          noteType={ExpertiseDamageNoteType.Bodywork}
          isDisabled={
            !canCustomerContinue ||
            !customerAbilityDefinitionTable[ExpertisePackageAbilityDefinitionType.KaportaKontrolNotlari]
          }
        />
      </Flex>
    </>
  );
};
