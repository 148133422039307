/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
/* eslint-disable no-console */
/* eslint-disable react/jsx-key */
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Box,
  DrawerFooter,
  Button,
  VStack,
  Center,
  useToast,
  Portal,
} from '@chakra-ui/react';
import { Api } from 'api';
import { LoadingText } from 'components';
import { FormControlFormSelect } from 'core';
import { useFormik } from 'formik';
import React, { useState } from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { BodyPartAnswerInfo, ExpertiseBodyDamagePartType, VehicleQueryCacheKeys } from 'types';
import { Socket } from 'socket.io-client';

export interface ExpertiseBodyworkIcDisControlSelectorProps {
  isOpen: boolean;
  onClose: () => void;
  isDisabled?: boolean;
}

type ExpertiseQuestionData = {
  [key: number]: string;
};

type ExpertiseSaveData = { answers: ExpertiseQuestionData };

export const ExpertiseBodyworkIcDisControlSelector: React.FC<ExpertiseBodyworkIcDisControlSelectorProps> = ({
  isOpen,
  onClose,
  isDisabled,
}) => {
  const customer = useSelector((selector: RootState) => selector.customer.focusedCustomer);
  const toast = useToast();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [customerSocket, setCustomerSocket] = useState<Socket | null>(null);

  const { data: questionsTemplate, isLoading: isQuestionsTemplateLoading } = useQuery(
    [VehicleQueryCacheKeys.GetBodyWorkIcDisControl, customer!.id],
    async () => {
      const { data: internalQuestionResp } = await Api.Expertise.getExpertiseBodyQuestions(
        ExpertiseBodyDamagePartType.InternalControl,
      );
      const { data: externalQuestionsResp } = await Api.Expertise.getExpertiseBodyQuestions(
        ExpertiseBodyDamagePartType.ExternalControl,
      );

      return {
        internalQuestions: internalQuestionResp.expertiseBodyQuestions,
        externalQuestions: externalQuestionsResp.expertiseBodyQuestions,
      };
    },
    {
      refetchOnWindowFocus: false,
      enabled: customer != null && isOpen,
      cacheTime: 0,
      onError: (err) => {
        console.error(err);
        toast({
          title: 'İç ve Dış kontrol verileri çekilirken hata oluştu',
          status: 'error',
          isClosable: true,
        });
      },
    },
  );

  const { data: questions, isLoading: isQuestionsLoading } = useQuery(
    VehicleQueryCacheKeys.GetBodyWorkExpertiseTemplates,
    async () => {
      const { data: internalResponse } = await Api.Expertise.getCustomerBodyPartsExpertise(
        customer!.id,
        ExpertiseBodyDamagePartType.InternalControl,
      );

      const { data: externalResponse } = await Api.Expertise.getCustomerBodyPartsExpertise(
        customer!.id,
        ExpertiseBodyDamagePartType.ExternalControl,
      );

      return {
        answers: internalResponse.expertiseBodyAnswers
          .concat(externalResponse.expertiseBodyAnswers)
          .reduce((accum: any, nextValue: BodyPartAnswerInfo) => {
            // eslint-disable-next-line no-param-reassign
            accum[nextValue.expertiseBodyQuestionId] = nextValue.answer;
            return accum;
          }, {} as ExpertiseQuestionData),
        // externalQuestionAnswers: externalResponse.expertiseBodyAnswers.reduce(
        //   (accum: any, nextValue: BodyPartAnswerInfo) => {
        //     // eslint-disable-next-line no-param-reassign
        //     accum[nextValue.expertiseBodyQuestionId] = nextValue.answer;
        //     return accum;
        //   },
        //   {} as ExpertiseQuestionData,
        // ),
      };
    },
    {
      refetchOnWindowFocus: false,
      enabled: customer != null && isOpen,
      cacheTime: 0,
      onError: (err) => {
        console.error(err);
        toast({
          title: 'İç ve Dış kontrol verileri çekilirken hata oluştu',
          status: 'error',
          isClosable: true,
        });
      },
    },
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      answers: (questions?.answers || {}) as ExpertiseQuestionData,
    },
    onSubmit: async (data: ExpertiseSaveData) => {
      if (questionsTemplate) {
        const filledAnswers = questionsTemplate?.internalQuestions
          .concat(questionsTemplate.externalQuestions)
          .reduce((accum, next) => {
            // eslint-disable-next-line no-param-reassign
            accum[next.id] = data.answers[next.id] || '-1';
            return accum;
          }, {} as any);

        const isNotCompleted = Object.entries(data.answers)
          .map(([, value]) => {
            return value === '-1';
          })
          .some((x) => x === true);

        if (isNotCompleted) {
          toast({
            title: 'Lütfen eksik seçim bırakmayınız.',
            status: 'error',
            isClosable: true,
          });
          return;
        }
        saveExpertiseMutate.mutateAsync({ answers: filledAnswers });
      }
    },
  });

  const saveExpertiseMutate = useMutation<void, any, ExpertiseSaveData>(
    async (data: ExpertiseSaveData) => {
      const bodyAnswers = Object.entries(data.answers).map(([key, value]) => {
        return {
          answer: value,
          expertiseBodyQuestionId: parseInt(key, 10),
        };
      });
      await Api.Expertise.saveBodyQuestionAnswers({
        customerId: customer!.id,
        bodyDamageAnswers: bodyAnswers,
      });
    },
    {
      onSuccess: () => {
        onClose();
        toast({
          title: 'Başarı ile kayıt edildi',
          status: 'success',
          isClosable: true,
        });
      },
      onError: (err) => {
        console.error(err);
        toast({
          title: 'Kayıt sırasında hata oluştu',
          status: 'error',
          isClosable: true,
        });
      },
    },
  );

  const { values, handleSubmit, setFieldValue } = formik;
  const showLoading = !customer || !questionsTemplate || isQuestionsTemplateLoading || isQuestionsLoading || !isOpen;

  return (
    <Portal>
      <Drawer isOpen={isOpen} onClose={onClose} size="xl">
        <DrawerOverlay />
        <DrawerContent overflow="auto">
          <form noValidate onSubmit={handleSubmit}>
            <DrawerCloseButton disabled={showLoading || saveExpertiseMutate.isLoading} />
            <DrawerHeader>İç ve Dış Kontrol Formu</DrawerHeader>

            <DrawerBody>
              {showLoading ? (
                <Center>
                  <LoadingText />
                </Center>
              ) : (
                <VStack gap={24} align="stretch">
                  <Box width="xs">
                    {questionsTemplate.internalQuestions.map((expertiseBodyQuestion) => (
                      <FormControlFormSelect
                        key={`ebqid${expertiseBodyQuestion.id}`}
                        label={expertiseBodyQuestion.question}
                        placeholder="Seçiniz"
                        onChange={(e) => {
                          setFieldValue(`answers.${expertiseBodyQuestion.id}`, e.target.value);
                        }}
                        value={values.answers[expertiseBodyQuestion.id] || '-1'}
                      >
                        {expertiseBodyQuestion.possibleAnswers?.map((ans) => (
                          <option value={ans.key} key={`vhsy${expertiseBodyQuestion.id}${ans.key}`}>
                            {ans.value}
                          </option>
                        ))}
                      </FormControlFormSelect>
                    ))}
                    {questionsTemplate.externalQuestions.map((expertiseBodyQuestion) => (
                      <FormControlFormSelect
                        key={`ebqid${expertiseBodyQuestion.id}`}
                        label={expertiseBodyQuestion.question}
                        placeholder="Seçiniz"
                        onChange={(e) => {
                          setFieldValue(`answers.${expertiseBodyQuestion.id}`, e.target.value);
                        }}
                        value={values.answers[expertiseBodyQuestion.id] || '-1'}
                      >
                        {expertiseBodyQuestion.possibleAnswers?.map((ans) => (
                          <option value={ans.key} key={`vhsy${expertiseBodyQuestion.id}${ans.key}`}>
                            {ans.value}
                          </option>
                        ))}
                      </FormControlFormSelect>
                    ))}
                  </Box>
                </VStack>
              )}
            </DrawerBody>

            <DrawerFooter>
              <Button
                variant="outline"
                mr={3}
                onClick={onClose}
                disabled={showLoading || saveExpertiseMutate.isLoading}
              >
                İptal
              </Button>
              {!isDisabled && (
                <Button type="submit" disabled={showLoading || saveExpertiseMutate.isLoading}>
                  Kaydet
                </Button>
              )}
            </DrawerFooter>
          </form>
        </DrawerContent>
      </Drawer>
    </Portal>
  );
};
