import { PlusSquareIcon } from '@chakra-ui/icons';
import {
  Flex,
  Heading,
  Spacer,
  Button,
  SimpleGrid,
  Stack,
  Box,
  useToast,
  Center,
  VStack,
  Text,
  Wrap,
  WrapItem,
  Image as ChakraImage,
  Divider,
} from '@chakra-ui/react';
import { Api } from 'api';
import {
  LoadingText,
  VehicleBrandSelect,
  VehicleColorSelect,
  VehicleFuelTypeSelect,
  VehicleImage,
  VehicleModelSelect,
  VehicleTypeSelect,
  VehicleYearSelect,
} from 'components';
import {
  DEFAULT_FORM_SIMPLE_GRID_LAYOUT,
  GenericMessages,
  NoSpecialCharacterRegex,
  chasisNoRegex,
  FormControlFormInputWithAddons,
  FormControlFormKMInput,
  FormControlFormChasisNoInput,
  FormControlFormMotorNoInput,
  useUploadClientFile,
  useGetUploadedClientFile,
  createCustomerSocket,
  FormControlFormTextarea,
  FORM_SELECT_SIMPLE_GRID_LAYOUT,
} from 'core';
import { hideLoading, refetchCustomer, setCustomerFormDirty, showLoading } from 'features';
import { useFormik } from 'formik';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import {
  CustomerExpertiseFileType,
  CustomerQueryCacheKey,
  CustomerVehiclePhotosResponse,
  ExpertisePackageAbilityDefinitionType,
  GetCustomerVehicleResponse,
  SaveCustomerVehicleRequest,
} from 'types';
import * as yup from 'yup';
import { Socket } from 'socket.io-client';

const validationSchema = yup.object({
  motorNo: yup
    .string()
    .required(GenericMessages.CannotBeEmpty)
    .matches(NoSpecialCharacterRegex, GenericMessages.NoSpecialCharater),
  chasisNo: yup
    .string()
    .required(GenericMessages.CannotBeEmpty)
    .matches(NoSpecialCharacterRegex, GenericMessages.NoSpecialCharater)
    .matches(chasisNoRegex, GenericMessages.InvalidChasisNo),

  km: yup.string().required(GenericMessages.CannotBeEmpty),
  color: yup.string().required(GenericMessages.CannotBeEmpty),
  carYear: yup.number().required(GenericMessages.CannotBeEmpty),
  brand: yup.number().required(GenericMessages.CannotBeEmpty),
  model: yup.number().required(GenericMessages.CannotBeEmpty),
  carType: yup.number().required(GenericMessages.CannotBeEmpty),
  fuelType: yup.string().required(GenericMessages.CannotBeEmpty),
  engineHp: yup
    .number()
    .required(GenericMessages.CannotBeEmpty)
    .max(999, () => GenericMessages.CantBeMoreThanNumber(999))
    .min(1, () => GenericMessages.CantBeLessThanNumber(1)),
});

const colorTextClear = (value: string) => {
  if (value.indexOf('GRI') !== -1) return 'Gri';
  if (value.indexOf('GRİ') !== -1) return 'Gri';
  if (value.indexOf('GR') !== -1) return 'Gri';
  if (value.indexOf('KIR') !== -1) return 'Kırmızı';
  if (value.indexOf('YES') !== -1) return 'Yeşil';
  if (value.indexOf('BEY') !== -1) return 'Beyaz';
  if (value.indexOf('BEJ') !== -1) return 'Bej';
  if (value.indexOf('KAHV') !== -1) return 'Kahverengi';
  if (value.indexOf('ALT') !== -1) return 'Altın';
  if (value.indexOf('LAC') !== -1) return 'Lacivert';
  if (value.indexOf('MAV') !== -1) return 'Mavi';
  if (value.indexOf('MOR') !== -1) return 'Mor';
  if (value.indexOf('PEM') !== -1) return 'Pembe';
  if (value.indexOf('SAR') !== -1) return 'Sarı';
  if (value.indexOf('SIY') !== -1) return 'Siyah';
  if (value.indexOf('SİY') !== -1) return 'Siyah';
  if (value.indexOf('TUR') !== -1) return 'Turuncu';
  if (value.indexOf('YEŞ') !== -1) return 'Yeşil';
  if (value.indexOf('YES') !== -1) return 'Yeşil';

  return value;
};

const fuelTypeTextClear = (value: string) => {
  if (value.indexOf('DIZ') !== -1) {
    return 'Dizel';
  }
  if (value.indexOf('BEN') !== -1) {
    return 'Benzin';
  }
  if (value.indexOf('LPG') !== -1) {
    return 'Benzin-LPG';
  }
  if (value.indexOf('ELE') !== -1) {
    return 'Elektrikli';
  }

  return value;
};

const carTypeTextClear = (value: string) => {
  if (value.indexOf('SEDAN') !== -1) {
    return 'Sedan';
  }
  if (value.indexOf('CAM') !== -1) {
    return 'Camlı Van';
  }
  if (value.indexOf('CAM') !== -1) {
    return 'Camlı Van';
  }
  if (value.indexOf('HATC') !== -1 || value.indexOf('2') !== -1) {
    return 'Hatchback 2 Kapı';
  }
  if (value.indexOf('HATC') !== -1 || value.indexOf('4') !== -1) {
    return 'Hatchback 4 Kapı';
  }
  if (value.indexOf('MIN') !== -1) {
    return 'Minibüs';
  }
  if (value.indexOf('SURG') !== -1) {
    return 'Panelvan Tek Sürgü';
  }
  if (value.indexOf('PAN') !== -1) {
    return 'Panelvan';
  }
  if (value.indexOf('PICK') !== -1 || value.indexOf('2') !== -1) {
    return 'Pickup 2 Kapı';
  }
  if (value.indexOf('PICK') !== -1 || value.indexOf('4') !== -1) {
    return 'Pickup 4 Kapı';
  }
  if (value.indexOf('STA') !== -1) {
    return 'Station';
  }

  return value;
};

const brandTextClear = (value: string) => {
  if (value.indexOf('ALF') !== -1) {
    return 'ALFA ROMEO';
  }
  if (value.indexOf('AST') !== -1) {
    return 'ASTON MARTIN';
  }
  if (value.indexOf('AUD') !== -1) {
    return 'AUDI';
  }
  if (value.indexOf('BENT') !== -1) {
    return 'BENTLEY';
  }
  if (value.indexOf('BUGA') !== -1) {
    return 'BUGATTI';
  }
  if (value.indexOf('CIT') !== -1) {
    return 'CITROEN';
  }
  if (value.indexOf('DAC') !== -1) {
    return 'DACIA';
  }
  if (value.indexOf('AUT') !== -1) {
    return 'DS AUTOMOBILES';
  }
  if (value.indexOf('FOR') !== -1) {
    return 'FORD';
  }
  if (value.indexOf('HYU') !== -1) {
    return 'HYUNDAI';
  }
  if (value.indexOf('HYU') !== -1) {
    return 'HYUNDAI';
  }
  if (value.indexOf('JAG') !== -1) {
    return 'JAGUAR';
  }
  if (value.indexOf('LAND') !== -1) {
    return 'LAND ROVER';
  }
  if (value.indexOf('LAND') !== -1) {
    return 'LAND ROVER';
  }
  if (value.indexOf('LEX') !== -1) {
    return 'LEXUS';
  }
  if (value.indexOf('MERC') !== -1) {
    return 'MERCEDES-BENZ';
  }
  if (value.indexOf('OPE') !== -1) {
    return 'OPEL';
  }
  if (value.indexOf('RENA') !== -1) {
    return 'RENAULT';
  }
  if (value.indexOf('SER') !== -1) {
    return 'SERES';
  }
  if (value.indexOf('VOLK') !== -1) {
    return 'VOLKSWAGEN';
  }
  if (value.indexOf('VOLV') !== -1) {
    return 'VOLVO';
  }

  return value;
};

type FormData = {
  motorNo: string;
  chasisNo: string;
  km?: number;
  color?: number;
  carYear?: number;
  engineHp?: number;
  fuelType?: number;
  carType?: number;
  brand?: number;
  model?: number;
  preExpertiseNotes?: string;
};

export const ExpertiseCarInformationPage: React.FC = () => {
  const toast = useToast();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const fileInputRefByLicenceImage = useRef<HTMLInputElement>(null);
  const fileInputRefByKmImage = useRef<HTMLInputElement>(null);
  const fileInputChasisImage = useRef<HTMLInputElement>(null);

  const { customerAbilityDefinitionTable, canCustomerContinue, customer } = useSelector((state: RootState) => ({
    customerAbilityDefinitionTable: state.customer.customerAbilityDefinitionTable,
    canCustomerContinue: state.customer.canCustomerContinue,
    customer: state.customer.focusedCustomer,
  }));

  const dispatch = useDispatch<AppDispatch>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [customerSocket, setCustomerSocket] = useState<Socket | null>(null);

  const {
    data: customerVehicle,
    isLoading,
    refetch: refetchCustomerVehicle,
  } = useQuery(
    [CustomerQueryCacheKey.GetCustomerVehicle, customer?.id],
    async () => {
      const response = await Api.Customer.getCustomerVehicle(customer!.id);
      if (!response.data) {
        return null;
      }
      const getCustomerVehicleResponse = response.data;
      return getCustomerVehicleResponse.customerVehicle;
    },
    {
      cacheTime: 0,
      retry: 0,
      enabled: customer != null,
      refetchOnWindowFocus: false,
    },
  );

  const { data: carImages, refetch: refetchCarImages } = useQuery(
    [CustomerQueryCacheKey.GetCustomerVehiclePhotos, customer?.id],
    async () => {
      const response = await Api.Customer.getCustomerVehiclePhotos(customer!.id);
      if (!response.data) {
        return null;
      }
      const getCustomerVehiclePhotosResponse = response.data;
      return getCustomerVehiclePhotosResponse.customerVehiclePhotos;
    },
    {
      cacheTime: 0,
      retry: 0,
      enabled: customer != null,
      refetchOnWindowFocus: false,
    },
  );

  const { data: kmPhotoResponse, refetch: refetchKmPhotoResponse } = useGetUploadedClientFile(
    customer?.id,
    CustomerExpertiseFileType.KMPhoto,
    {
      onError: (err: any) => {
        console.error(err);
        toast({
          title: 'Araç testi sonuçları çekilirken hata oluştu',
          status: 'error',
        });
      },
    },
  );

  const { data: chasisPhotoResponse, refetch: refetchChasisPhotoResponse } = useGetUploadedClientFile(
    customer?.id,
    CustomerExpertiseFileType.ChassisPhoto,
    {
      onError: (err: any) => {
        console.error(err);
        toast({
          title: 'Araç testi sonuçları çekilirken hata oluştu',
          status: 'error',
        });
      },
    },
  );

  const carImageDeleteMutation = useMutation<void, void, number>(
    async (photoId) => {
      dispatch(showLoading());
      await Api.Customer.deleteCustomerVehiclePhoto(photoId);
    },
    {
      onSuccess() {
        refetchCarImages();
      },
      onSettled() {
        dispatch(hideLoading());
      },
      onError() {
        toast({
          status: 'error',
          title: 'Resim silinirken hata oluştu',
          isClosable: true,
        });
      },
    },
  );

  const saveCustomerVehicleImageMutation = useMutation<void, any, File>(
    async (file: File) => {
      dispatch(showLoading());
      await Api.Customer.saveCustomerVehiclePhoto({
        file,
        customerId: customer!.id,
      });
    },
    {
      onSuccess() {
        refetchCarImages();
      },
      onSettled() {
        dispatch(hideLoading());
      },
    },
  );

  const updateCustomerVeicleImageMutation = useMutation<void, any, { file: File; id: number }>(
    async ({ file, id }) => {
      dispatch(showLoading());
      await Api.Customer.saveCustomerVehiclePhoto({
        file,
        customerId: customer!.id,
        id,
      });
    },
    {
      onSuccess() {
        refetchCarImages();
      },
      onSettled() {
        dispatch(hideLoading());
      },
    },
  );

  const chasisAndKilometerMutation = useMutation(async () => {
    const response = await Api.Ocr.chasisNoAndKilometerCheckByOcr(customer!.id);
    return response;
  });

  const mutation = useMutation<void, any, SaveCustomerVehicleRequest>(
    async (request: SaveCustomerVehicleRequest) => {
      await Api.Customer.saveCustomerVehicleInfo(request);
    },
    {
      onSuccess: () => {
        dispatch(setCustomerFormDirty(false));
        dispatch(refetchCustomer(customer!.id));
        toast({
          title: 'Araç bilgileri basari ile kayit edildi',
          status: 'success',
          isClosable: true,
        });
      },
      onError: (error: any) => {
        console.error(error);
        if (error && error.response) {
          if (error.response.status === 400 && error.response.data) {
            toast({
              title: error.response.data?.message || 'Araç bilgileri kayit edilirken hata olustu',
              status: 'error',
              isClosable: true,
            });
          }
        } else {
          toast({
            title: 'Araç bilgileri kayit edilirken hata olustu',
            status: 'error',
            isClosable: true,
          });
        }
      },
    },
  );
  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: {
      motorNo: customerVehicle?.engineNumber || '',
      chasisNo: customerVehicle?.chassisNumber || '',
      km: customerVehicle?.kilometer,
      color: customerVehicle?.colorId,
      carYear: customerVehicle?.modelYear,
      carType: customerVehicle?.vehicleTypeId,
      fuelType: customerVehicle?.fuelTypeId,
      engineHp: customerVehicle?.engineHp,
      brand: customerVehicle?.brandId,
      model: customerVehicle?.modelId,
      preExpertiseNotes: customerVehicle?.preExpertiseNotes || '',
    },
    onSubmit: async (data: FormData) => {
      const formattedKm = data?.km?.toString().replaceAll('.', '').replaceAll(',', '');

      await mutation.mutateAsync({
        engineNumber: data.motorNo,
        chassisNumber: data.chasisNo,
        kilometer: +formattedKm!,
        colorId: data.color!,
        modelYear: data.carYear!,
        fuelTypeId: data.fuelType!,
        modelId: data.model!,
        brandId: data.brand!,
        engineHp: data.engineHp!,
        customerId: customer!.id,
        vehicleTypeId: data.carType!,
        preExpertiseNotes: data.preExpertiseNotes,
      });

      if (data?.chasisNo.startsWith('EGM')) {
        toast({
          title:
            'Lütfen kaporta notlarında şase numarasının değiştiğini belirtin” Tamir, tadil, çürüme, değişme gibi çeşitli sebeplerle şase numarası kaybolan motor ve şaselere emniyet genel müdürlüğü tarafından yeni numara verilmektedir.',
          status: 'warning',
          isClosable: true,
        });
      }

      if (data?.motorNo.toLowerCase().startsWith('egm')) {
        toast({
          title: 'Aracın motor numarası EGM tarafından yeniden düzenlenmiştir.',
          status: 'warning',
          isClosable: true,
        });
      }
    },
    validationSchema,
  });

  const handleVisualsSubmit = async () => {
    if (!kmPhotoResponse || !chasisPhotoResponse) {
      toast({
        title: 'Lütfen KM ve Şase fotoğraflarını yükleyin',
        status: 'warning',
        isClosable: true,
      });
      return;
    }

    if (!formik.values.km || !formik.values.chasisNo) {
      toast({
        title: 'Lütfen tüm alanları doldurun',
        status: 'warning',
        isClosable: true,
      });
      return;
    }

    if (kmPhotoResponse && chasisPhotoResponse) {
      toast({
        title: 'Görseller başarıyla kayıt edildi',
        status: 'success',
        isClosable: true,
      });
    }

    // after save vehicle info, check chasis and kilometer is compatible with ocr
    const chasisAndKilometerResponse = await chasisAndKilometerMutation.mutateAsync();
    if (chasisAndKilometerResponse?.isSuccess && chasisAndKilometerResponse.data?.length > 0) {
      chasisAndKilometerResponse.data?.forEach((message: string) => {
        toast({
          title: message,
          status: 'warning',
          isClosable: true,
        });
      });
    }
  };
  const { values, handleChange, errors, handleSubmit, dirty } = formik;

  const onFileChange: React.ChangeEventHandler<HTMLInputElement> = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length === 1) {
      // onImageChange(e.target.files[0]);
      saveCustomerVehicleImageMutation.mutate(e.target.files[0]);
    }
  };

  const uploadKmPhotoMutation = useUploadClientFile(customer?.id, CustomerExpertiseFileType.KMPhoto, {
    onMutate: () => {
      dispatch(showLoading());
    },
    onSettled: () => {
      dispatch(hideLoading());
      fileInputRefByKmImage.current!.value = '';

      // eslint-disable-next-line no-use-before-define
      refetchKmPhotoResponse();
    },
    onError: (error: any) => {
      console.error(error);
      toast({
        status: 'error',
        title: 'Dosya yüklenirken hata oluştu',
        isClosable: true,
      });
    },
  });

  const onFileChangeByKmImage: React.ChangeEventHandler<HTMLInputElement> = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (e.target.files && e.target.files.length === 1) {
      uploadKmPhotoMutation.mutate(e.target.files[0]);
    }
  };

  const uploadChasisPhotoMutation = useUploadClientFile(customer?.id, CustomerExpertiseFileType.ChassisPhoto, {
    onMutate: () => {
      dispatch(showLoading());
    },
    onSettled: () => {
      dispatch(hideLoading());
      fileInputChasisImage.current!.value = '';
      // eslint-disable-next-line no-use-before-define
      refetchChasisPhotoResponse();
    },
    onError: (error: any) => {
      console.error(error);
      toast({
        status: 'error',
        title: 'Dosya yüklenirken hata oluştu',
        isClosable: true,
      });
    },
  });

  const onFileChangeByChasisImage: React.ChangeEventHandler<HTMLInputElement> = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (e.target.files && e.target.files.length === 1) {
      uploadChasisPhotoMutation.mutate(e.target.files[0]);
    }
  };

  function setOcrVehicleValueBySelectElement(key: string, value: string) {
    const select = document.querySelector(`#carInfoForm select[name="${key}"]`) as HTMLSelectElement;
    const options = Array.from(select.options);

    let isSucces = false;
    formik.setFieldValue(key, '');
    /* eslint-disable */
    for (const valueItem of value.split(' ')) {
      const filterResult = options.filter((p) => p.text === valueItem);

      if (filterResult.length === 1) {
        formik.setFieldValue(key, filterResult[0].value);
        isSucces = true;
        break;
      }
    }
    if (!isSucces) formik.setFieldError(key, 'Bu alan boş olamaz');
  }

  const ocrMutation = useMutation<void, any, File>(
    async (files: File) => {
      dispatch(showLoading());
      await Api.Ocr.ocrLicencePhoto({ files }).then((data) => {
        formik.setErrors({});

        //color Start
        const colorData = data.filter((p) => p.key == 'color');
        if (colorData.length === 1) {
          colorData[0].value = colorTextClear(colorData[0].value);
          setOcrVehicleValueBySelectElement(colorData[0].key, colorData[0].value);
        }
        //color End

        //fuelType Start
        const fuelTypeData = data.filter((p) => p.key == 'fuelType');
        if (fuelTypeData.length === 1) {
          fuelTypeData[0].value = fuelTypeTextClear(fuelTypeData[0].value);
          setOcrVehicleValueBySelectElement(fuelTypeData[0].key, fuelTypeData[0].value);
        }
        //fuelType End

        //carType Start
        let carTypeData = data.filter((p) => p.key == 'carType');
        if (carTypeData.length === 1) {
          carTypeData[0].value = carTypeTextClear(carTypeData[0].value);
          setOcrVehicleValueBySelectElement(carTypeData[0].key, carTypeData[0].value);
        }
        //carType End

        //brand Start
        const brandData = data.filter((p) => p.key == 'brand');
        if (brandData.length === 1) {
          brandData[0].value = brandTextClear(brandData[0].value);
          setOcrVehicleValueBySelectElement(brandData[0].key, brandData[0].value);
        }
        //brand End

        //model Start
        setTimeout(() => {
          const modelData = data.filter((p) => p.key == 'model');
          if (modelData.length === 1) {
            setOcrVehicleValueBySelectElement(modelData[0].key, modelData[0].value);
          }
        }, 400);
        //model End

        //carYear Start
        const carYearData = data.filter((p) => p.key == 'carYear');
        if (carYearData.length === 1) {
          setOcrVehicleValueBySelectElement(carYearData[0].key, carYearData[0].value);
        }
        //carYear End

        //motorNo Start
        const motorNoData = data.filter((p) => p.key == 'motorNo');
        if (motorNoData.length === 1) {
          formik.setFieldValue(motorNoData[0].key, motorNoData[0].value);
        }
        //motorNo End

        //chasisNo Start
        const chasisNoData = data.filter((p) => p.key == 'chasisNo');
        if (chasisNoData.length === 1) {
          formik.setFieldValue(chasisNoData[0].key, '');
          chasisNoData[0].value = chasisNoData[0].value
            .replace('O', '0')
            .replace('o', '0')
            .replace('I', '1')
            .replace('İ', '1');
          if (chasisNoRegex.test(chasisNoData[0].value)) {
            formik.setFieldValue(chasisNoData[0].key, chasisNoData[0].value);
          }
        }
        //chasisNo End

        //engineHp Start
        const engineHpData = data.filter((p) => p.key == 'engineHp');
        if (engineHpData.length === 1) {
          formik.setFieldValue(engineHpData[0].key, '');
          engineHpData[0].value = engineHpData[0].value.replace('ke', '').replace('kw', '').replace('kv', '').trim();
          const hp = parseInt(engineHpData[0].value) || 0;
          if (hp > 9 && hp < 1000) formik.setFieldValue(engineHpData[0].key, hp);
        }
        //engineHp End

        //km Start
        const kmData = data.filter((p) => p.key == 'km');
        if (kmData.length === 1) {
          formik.setFieldValue(kmData[0].key, '');
          kmData[0].value = kmData[0].value.replace('ke', '').replace('kw', '').replace('kv', '').trim();
          const km = parseInt(kmData[0].value) || -1;
          if (km > -1) formik.setFieldValue(kmData[0].key, km);
        }
        //km End
      });
    },
    {
      onSettled() {
        dispatch(hideLoading());
      },
    },
  );

  const onFileChangeBylicenseImage: React.ChangeEventHandler<HTMLInputElement> = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (e.target.files && e.target.files.length === 1) {
      ocrMutation.mutate(e.target.files[0]);
    }
  };

  const onDeleteImage = (photoId: number) => {
    carImageDeleteMutation.mutate(photoId);
  };

  const checkChassisNumber = async (e: React.FocusEvent<HTMLInputElement>) => {
    const chassisNumber = e.target.value;
    if (chassisNumber.length === 17) {
      const { data } = await Api.Customer.getCustomerByChassisNumber(chassisNumber);
      formik.setFieldValue('motorNo', data.customer.customerVehicle?.engineNumber);
      formik.setFieldValue('color', data.customer.customerVehicle?.colorId);
      formik.setFieldValue('carYear', data.customer.customerVehicle?.modelYear);
      formik.setFieldValue('fuelType', data.customer.customerVehicle?.fuelTypeId);
      formik.setFieldValue('model', data.customer.customerVehicle?.modelId);
      formik.setFieldValue('brand', data.customer.customerVehicle?.brandId);
      formik.setFieldValue('engineHp', data.customer.customerVehicle?.engineHp);
      formik.setFieldValue('carType', data.customer.customerVehicle?.vehicleTypeId);
    }
  };

  const canUploadCarImage = useMemo(() => {
    if (
      !customerAbilityDefinitionTable[ExpertisePackageAbilityDefinitionType.AracFotografiCoklu] &&
      !customerAbilityDefinitionTable[ExpertisePackageAbilityDefinitionType.AracFotografiTekli]
    ) {
      return false;
    }

    if (customerAbilityDefinitionTable[ExpertisePackageAbilityDefinitionType.AracFotografiTekli]) {
      return carImages && carImages.length < 1;
    }

    return true;
  }, [customerAbilityDefinitionTable, carImages]);

  useEffect(() => {
    dispatch(setCustomerFormDirty(dirty));
  }, [dirty]);

  useEffect(() => {
    if (customer) {
      const newCustomerSocket = createCustomerSocket(customer.id.toString());
      setCustomerSocket(newCustomerSocket);

      newCustomerSocket.on('customerVehicleChanged', (data: GetCustomerVehicleResponse) => {
        if (data.customerVehicle.customer?.id === customer.id) {
          refetchCustomerVehicle();
        }
      });

      newCustomerSocket.on('customerVehiclePhotoChanged', (data: CustomerVehiclePhotosResponse) => {
        if (data) {
          refetchCarImages();
        }
      });

      return () => {
        newCustomerSocket.disconnect();
      };
    }
  }, [customer, refetchCustomerVehicle, refetchCarImages]);

  if (isLoading) {
    return (
      <Center>
        <LoadingText />
      </Center>
    );
  }

  return (
    <>
      <input
        hidden
        name="license_image"
        type="file"
        accept="image/*"
        ref={fileInputRefByLicenceImage}
        onChange={onFileChangeBylicenseImage}
      />
      <input
        hidden
        type="file"
        accept="image/*"
        ref={fileInputRef}
        onChange={onFileChange}
        multiple={customerAbilityDefinitionTable[ExpertisePackageAbilityDefinitionType.AracFotografiCoklu]}
      />
      <input
        hidden
        type="file"
        accept="image/*"
        ref={fileInputRefByKmImage}
        onChange={onFileChangeByKmImage}
        multiple={customerAbilityDefinitionTable[ExpertisePackageAbilityDefinitionType.AracFotografiCoklu]}
      />
      <input
        hidden
        type="file"
        accept="image/*"
        ref={fileInputChasisImage}
        onChange={onFileChangeByChasisImage}
        multiple={customerAbilityDefinitionTable[ExpertisePackageAbilityDefinitionType.AracFotografiCoklu]}
      />
      <form noValidate onSubmit={handleSubmit} id="carInfoForm">
        <Flex align="stretch" direction="column" basis="100%" gap="2">
          <Heading as="h4" size="md">
            Araç Bilgileri
          </Heading>
          <Spacer />
          <SimpleGrid columns={FORM_SELECT_SIMPLE_GRID_LAYOUT}>
            <FormControlFormTextarea
              label="Ekspertiz Öncesi Tespit Edilen Durumlar"
              name="preExpertiseNotes"
              value={values.preExpertiseNotes}
              onChange={handleChange}
              maxLength={400}
            />
          </SimpleGrid>
          <Spacer />

          <Stack direction={['column', 'row']} spacing="24px">
            <Button
              onClick={() => {
                fileInputRefByLicenceImage.current?.click();
              }}
            >
              Ruhsat Tara
            </Button>
          </Stack>
          <Spacer />
          <SimpleGrid spacing="30px" columns={DEFAULT_FORM_SIMPLE_GRID_LAYOUT}>
            <FormControlFormChasisNoInput
              label="Şase No"
              name="chasisNo"
              onBlur={checkChassisNumber}
              onChange={handleChange}
              value={values.chasisNo}
              error={errors.chasisNo != null}
              helperText={errors.chasisNo}
              isDisabled={!canCustomerContinue}
            />
            <FormControlFormMotorNoInput
              maxLength={20}
              label="Motor No"
              name="motorNo"
              onChange={handleChange}
              value={values.motorNo}
              error={errors.motorNo != null}
              helperText={errors.motorNo}
              isDisabled={!canCustomerContinue}
            />
            <FormControlFormKMInput
              label="KM"
              name="km"
              onChange={handleChange}
              value={values.km?.toLocaleString() || ''}
              error={errors.km != null}
              helperText={errors.km}
              isDisabled={!canCustomerContinue}
            />
            <VehicleColorSelect
              name="color"
              onChange={handleChange}
              value={values.color}
              error={errors.color != null}
              helperText={errors.color}
              isDisabled={!canCustomerContinue}
            />
          </SimpleGrid>
          <Spacer />
          <Heading as="h4" size="md">
            Araç Marka/Model Bilgileri
          </Heading>
          <Spacer />

          <SimpleGrid spacing="30px" columns={DEFAULT_FORM_SIMPLE_GRID_LAYOUT}>
            <VehicleTypeSelect
              name="carType"
              onChange={handleChange}
              value={values.carType}
              error={errors.carType != null}
              helperText={errors.carType}
              isDisabled={!canCustomerContinue}
            />
            <VehicleYearSelect
              name="carYear"
              onChange={handleChange}
              value={values.carYear}
              error={errors.carYear != null}
              helperText={errors.carYear}
              isDisabled={!canCustomerContinue}
            />
            <VehicleBrandSelect
              name="brand"
              onChange={handleChange}
              value={values.brand}
              error={errors.brand != null}
              helperText={errors.brand}
              isDisabled={!canCustomerContinue}
            />
            <VehicleModelSelect
              brandId={values.brand}
              name="model"
              value={values.model}
              onChange={handleChange}
              error={errors.model != null}
              helperText={errors.model}
              isDisabled={!canCustomerContinue}
            />
            {/* <FormControlFormInput
              label="Beygir Gucu"
              name="engineHp"
              onChange={handleChange}
              value={values.engineHp || ''}
              error={errors.engineHp != null}
              helperText={errors.engineHp}
              isDisabled={!canCustomerContinue}
              type="number"
            /> */}
            <FormControlFormInputWithAddons
              label="Motor Gücü"
              name="engineHp"
              inputAddonProps={{
                placement: 'right',
              }}
              addon="kW"
              onChange={handleChange}
              value={values.engineHp || ''}
              error={errors.engineHp != null}
              helperText={errors.engineHp}
              isDisabled={!canCustomerContinue}
              type="number"
            />
            <VehicleFuelTypeSelect
              name="fuelType"
              onChange={handleChange}
              value={values.fuelType}
              error={errors.fuelType != null}
              helperText={errors.fuelType}
              isDisabled={!canCustomerContinue}
            />
          </SimpleGrid>

          <Spacer />

          {canCustomerContinue && (
            <Box width="100%" display="flex" justifyContent="flex-end" marginTop="10">
              <Button type="submit" isLoading={mutation.isLoading}>
                Kaydet
              </Button>
            </Box>
          )}

          <Spacer />

          <Divider />
          <Wrap spacing="30px" justify="space-between">
            <WrapItem flexDirection="column">
              <Heading as="h4" size="md">
                Araç Resimleri
              </Heading>
              <Wrap spacing="30px">
                {carImages &&
                  carImages.map((carImage) => (
                    <WrapItem key={`cvci${carImage.id}`}>
                      <VehicleImage
                        photoUrl={carImage.photoUrl}
                        isActionsDisabled={!canCustomerContinue}
                        onDeleteImage={() => onDeleteImage(carImage.id)}
                        onImageChange={(file: File) =>
                          updateCustomerVeicleImageMutation.mutate({ file, id: carImage.id })
                        }
                      />
                    </WrapItem>
                  ))}
                {canCustomerContinue && (
                  <WrapItem>
                    <Button
                      colorScheme="teal"
                      variant="outline"
                      width="128px"
                      height="128px"
                      onClick={() => {
                        fileInputRef.current?.click();
                      }}
                      disabled={!canUploadCarImage}
                    >
                      <VStack>
                        <PlusSquareIcon w={8} h={8} />
                        <Text>Resim Ekle</Text>
                      </VStack>
                    </Button>
                  </WrapItem>
                )}
              </Wrap>
            </WrapItem>
            <WrapItem flexDirection="column">
              <Heading as="h4" size="md">
                Km Fotoğrafı
              </Heading>
              <Wrap spacing="30px">
                <Wrap>
                  {canCustomerContinue && (
                    <WrapItem>
                      <Button
                        colorScheme="teal"
                        variant="outline"
                        width="128px"
                        height="128px"
                        onClick={() => {
                          fileInputRefByKmImage.current?.click();
                        }}
                      >
                        <VStack>
                          <PlusSquareIcon w={8} h={8} />
                          {kmPhotoResponse ? (
                            <Text fontSize="xs" whiteSpace="break-spaces" wordBreak="break-word">
                              KM Fotoğrafını Değiştir
                            </Text>
                          ) : (
                            <Text fontSize="xs" whiteSpace="break-spaces" wordBreak="break-word">
                              KM Fotoğrafı Yükle
                            </Text>
                          )}
                        </VStack>
                      </Button>
                    </WrapItem>
                  )}
                  {kmPhotoResponse && (
                    <ChakraImage
                      src={kmPhotoResponse?.url}
                      alt="Km Fotoğrafı"
                      objectFit="cover"
                      boxSize="130px"
                      onClick={() => {
                        const link = document.createElement('a');
                        link.target = '_blank';
                        link.href = kmPhotoResponse?.url || '';
                        link.click();
                      }}
                    />
                  )}
                </Wrap>
              </Wrap>
            </WrapItem>
            <WrapItem flexDirection="column">
              <Heading as="h4" size="md">
                Şasi Fotoğrafı
              </Heading>
              <Wrap spacing="30px">
                <Wrap>
                  {canCustomerContinue && (
                    <WrapItem>
                      <Button
                        colorScheme="teal"
                        variant="outline"
                        width="128px"
                        height="128px"
                        onClick={() => {
                          fileInputChasisImage.current?.click();
                        }}
                      >
                        <VStack>
                          <PlusSquareIcon w={8} h={8} />
                          {chasisPhotoResponse ? (
                            <Text fontSize="xs" whiteSpace="break-spaces" wordBreak="break-word">
                              Şasi Fotoğrafını Değiştir
                            </Text>
                          ) : (
                            <Text fontSize="xs" whiteSpace="break-spaces" wordBreak="break-word">
                              Şasi Fotoğrafı Yükle
                            </Text>
                          )}
                        </VStack>
                      </Button>
                    </WrapItem>
                  )}
                  {chasisPhotoResponse && (
                    <ChakraImage
                      src={chasisPhotoResponse?.url}
                      alt="Şasi Fotoğrafı"
                      objectFit="cover"
                      boxSize="130px"
                      onClick={() => {
                        const link = document.createElement('a');
                        link.target = '_blank';
                        link.href = chasisPhotoResponse?.url || '';
                        link.click();
                      }}
                    />
                  )}
                </Wrap>
              </Wrap>
            </WrapItem>
          </Wrap>

          {canCustomerContinue && (
            <Box width="100%" display="flex" justifyContent="flex-end" marginTop="10">
              <Button type="button" onClick={handleVisualsSubmit} isLoading={chasisAndKilometerMutation.isLoading}>
                Kaydet
              </Button>
            </Box>
          )}
        </Flex>
      </form>
    </>
  );
};
